import React, { useState, useEffect, useRef } from 'react';
import { useResize } from './hooks/useResize';

import vinceBrazilImg from './assets/vince-brazil.svg';
import vinceGif from './assets/vinicius-junior-camisa18-ezgif.com-crop.gif';
import vinceNopeGif from './assets/vince-coming-soon.gif';

import './App.css';
import Button from './Components/Button';
import Card from './Components/Card';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

// Social Icons
import { ReactComponent as LogoX } from './assets/x-logo.svg';
import { ReactComponent as LogoTelegram } from './assets/telegram-logo.svg';
import { ReactComponent as LogoDexscreener } from './assets/dexscreener-logo.svg';
import { ReactComponent as LogoDexTools } from './assets/dextools-logo.svg';
import { ReactComponent as LogoCoinMarketCap } from './assets/cmc-logo.svg';
import { ReactComponent as LogoCoinGecko } from './assets/cg-logo.svg';

ChartJS.register(ArcElement, Tooltip, Legend);

let timerId = null;

function ShowVini(event = false, nope = false) {
  if (event && nope) {
    event.preventDefault();
  }

  // set vini gif bottom to 0
  const image = new Image();
  image.src = vinceGif;

  const gifDiv = document.querySelector('.vini-gif');

  if (!gifDiv) {
    return;
  }

  let timeout = 4000;

  if (nope) {
    gifDiv.querySelector('img').src = vinceNopeGif;
    timeout = 2000;
  } else {
    gifDiv.querySelector('img').src = vinceGif;
  }

  gifDiv.style.bottom = '50%';

  // Clear the previous timer
  if (timerId) {
    clearTimeout(timerId);
  }

  // Set a new timer
  timerId = setTimeout(() => {
    gifDiv.style.bottom = '-100%';
  }, timeout);
}

async function fetchMarketCap() {
  // const chainId = 'solana'
  const ca = '8BQdCwV2dtEpPRcwk285MWNZZN2K6jfQ3hFobg7NDASt';

  return await fetch(`https://api.dexscreener.com/latest/dex/tokens/${ca}`)
    .then(res => res.json())
    .then(data => {
      if (!data.pairs || !data.pairs.length) {
        return 0;
      }

      const marketCap = data.pairs[0].fdv;

      // TODO: save the highest market cap to a file to mark checkpoints

      return marketCap;
    });
}

function moneyFormat(value) {
  if (!value) {
    return 0;
  }

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function App() {
  const [marketCap, setMarketCap] = useState(null);
  const donationMeterMarketCapComponentRef = useRef();
  const donationMeterMarketCapWidth = useResize(donationMeterMarketCapComponentRef).width;

  useEffect(() => {
    fetchMarketCap().then(setMarketCap);
  }, []);

  const charityCheckpoints = [
    [
      250000,
      .2
    ],
    [
      500000,
      .3
    ],
    [
      750000,
      .4
    ],
    [
      1000000,
      .5
    ]
  ];


  return (
    <div className="App">
      <header className="App-header">
        <h1>$VINCE</h1>

        <div className="inner">
          <p><strong>Vincencius Jooniur</strong></p>

          <p>
            $VINCE is the best upcoming #PlayerFi on Solana! Primed up for Copa America!
          </p>

          <div className="header-socials">
            <a href="https://x.com/VinceJooniur" target="_blank">
              <LogoX />
            </a>
            <a href="https://t.me/+7u-a-FMCyto2YTc0" target="_blank">
              <LogoTelegram />
            </a>
          </div>

          <div className="header-financial">
            <div class="logos-left">
              <a href="https://dexscreener.com/solana/a9l5qyum3apugzyflspxhjs6cbps9fubxdh46sig1wvc" target="_blank" className="disabled">
                <LogoDexscreener />
              </a>

              <a href="https://www.dextools.io/app/en/solana/pair-explorer/A9L5QyuM3aPugzYFLSpxHjS6cbps9FUBXdh46siG1wvC" target="_blank" className="disabled">
                <LogoDexTools />
              </a>
            </div>

            <Button href="https://raydium.io/swap/?inputMint=sol&outputMint=8BQdCwV2dtEpPRcwk285MWNZZN2K6jfQ3hFobg7NDASt" target="_blank">
              Buy $VINCE on Raydium
            </Button>

            <div className="logos-right">
              <a href="#" target="_blank" className="disabled">
                <LogoCoinMarketCap onClick={(e) => {
                  ShowVini(e, true)
                }} />
              </a>

              <a href="#" target="_blank" className="disabled">
                <LogoCoinGecko onClick={(e) => {
                  ShowVini(e, true)
                }} />
              </a>
            </div>
          </div>
        </div>

        <div className="vince-shake">
          <img src={vinceBrazilImg} alt="$VINCE coin" width="444" />
        </div>
      </header>

      <section>
        <div className="container">
          <div className="inner">
            <h2>What is $VINCE?</h2>
            <p>
              Join the hype with $VINCE, the official memecoin inspired by the electrifying skills and spirit of Vincencius Jooniur! Combining the thrill of crypto with the flair of one of football’s brightest stars, $VINCE is not just a token! It’s a celebration of speed, agility, and unstoppable energy.
            </p>
            <p>
              With Copa America on the horizon and Vincencius Jooniur in the running for the Ballon d'Or, $VINCE is set to skyrocket! Don't miss out on the explosive growth! Join the $VINCE community now and ride the wave to the top!
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="inner">
            <h2>Contract Address</h2>
            <div className="contract-address">
              <input type="text" value="8BQdCwV2dtEpPRcwk285MWNZZN2K6jfQ3hFobg7NDASt" readOnly onClick={(e) => e.target.select()} />
              <Button
                onClick={() => {
                  navigator.clipboard.writeText("8BQdCwV2dtEpPRcwk285MWNZZN2K6jfQ3hFobg7NDASt");
                  ShowVini();
                }}
              >
                Copy
              </Button>
            </div>

            <p>Total Supply: 1,000,000,000</p>

            <Button href="https://raydium.io/swap/?inputMint=sol&outputMint=8BQdCwV2dtEpPRcwk285MWNZZN2K6jfQ3hFobg7NDASt" target="_blank">
              Buy $VINCE on Raydium
            </Button>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="inner">
            <h2>Vincenomics</h2>
            <div className="row text-left">
              <div className="col">
                <Doughnut
                  className="tokenomics-chart"
                  data={{
                    labels: ["Liquidity Pool (LP)", "Burning", "Charity", "Marketing", "Team"],
                    datasets: [
                      {
                        // label: '%', 
                        data: [88, 3, 2, 4, 3],
                        backgroundColor: ['#ead40c', '#c0392b', '#004aad', '#3cdd37', '#ab6146'],
                      }
                    ],
                  }}
                  plugins={{
                    tooltip: {
                      callbacks: {
                        label: function (tooltipItem, data) {
                          //get the concerned dataset
                          var dataset = data.datasets[tooltipItem.datasetIndex];
                          //calculate the total of this data set
                          var total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
                            return previousValue + currentValue;
                          });
                          //get the current items value
                          var currentValue = dataset.data[tooltipItem.index];
                          //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
                          var percentage = Math.floor(((currentValue / total) * 100) + 0.5);

                          return percentage + "%";
                        }
                      }
                    }
                  }
                  }
                />
              </div>
              <div className="col">
                <p>
                  $VINCE has a total supply of 1,000,000,000 tokens. The distribution is as follows:
                </p>
                <ul>
                  <li>88% Liquidity Pool (LP)</li>
                  <li>3% Burning</li>
                  <li>2% Charity to Anti - Bullying Organisation</li>
                  <li>4% Marketing</li>
                  <li>3% team</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="inner">
            <h2>$VINCE Anti Bulliying Charity</h2>

            <p>
              Inspired by the resilience of Vinícius Júnior amidst the challenges of media and fan-based bullying, our project has dedicated 2% of its total supply to support anti-bullying charities. We believe in standing with Vinícius and all individuals facing adversity, using our platform to foster a culture of respect and kindness in the crypto community and beyond.
            </p>

            <div className="charity-checkpoints">
              <div className="charity-checkpoints-inner">
                {charityCheckpoints.map((checkpoint, index) => {
                  return (
                    <div
                      key={index}
                      className={`checkpoint${marketCap >= checkpoint[0] ? ' reached' : ''}`}
                      data-mcap={checkpoint[0]}
                    // style={{
                    //   width
                    // }}
                    >
                      <div className="checkpoint-label">
                        $ {moneyFormat(checkpoint[0])}
                      </div>
                      <div className="checkpoint-marker"></div>

                    </div>
                  );
                })}
                <div
                  className="progress"
                  style={{
                    width: `${Math.min((marketCap / 1000000) * 100, 100)}%`
                  }}
                ></div>
              </div>

            </div>

            <div
              ref={donationMeterMarketCapComponentRef}
              className="market-cap"
              style={{
                left: `${(marketCap / 1000000) * 100}%`,
                // set transform x to 50% if half of the div's width is reached
                // transform: (donationMeterMarketCapWidth / 2) < wind
              }}
            >
              <div>Current Market Cap</div>
              <div><strong>$ {moneyFormat(marketCap)}</strong></div>
              <div>Donated</div>
              {/* the second argument of checkpoints is the percentage of the total supply that will be donated when the market cap reaches the first argument so when it reached 500k for example there was .1% donated for 250k and .3% for 500k */}
              <div><strong>$ {moneyFormat(charityCheckpoints.reduce((acc, checkpoint) => {
                if (marketCap >= checkpoint[0]) {
                  const pricePerToken = checkpoint[0] / 1000000000; // 1 Billion tokens
                  const tokensDonated = 1000000000 * (checkpoint[1] / 100);
                  const dollarValue = tokensDonated * pricePerToken;
                  return acc + dollarValue;
                }

                return acc;
              }, 0))}</strong></div>
            </div>

            <h3 className="mt-2">How the Charity Works</h3>
            <p>
              $VINCE is committed to supporting anti-bullying charities with a percentage of our total supply. When the market cap reaches specific checkpoints, we donate a percentage of the total supply to anti-bullying organizations. By investing in $VINCE, you are investing in a brighter, kinder future for all.
            </p>
            <p>
              For now we've decided to donate to <a href="https://www.stompoutbullying.org/">https://www.stompoutbullying.org/</a>
            </p>
            <p>
              We have more charity organizations in mind and we will let the community decide where to donate next.
            </p>
            <ul className="text-left">
              <li>At 250K - 0.2% of Total Supply ($500)</li>
              <li>At 500K - 0.3% of Total Supply ($1500)</li>
              <li>At 750K - 0.4% of Total Supply ($3000)</li>
              <li>At 1M - 0.5% of Total Supply ($5000)</li>
              <li>After 1M, for every +250k mc achieved we will donate +$1000</li>
            </ul>

          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="inner">
              <h2>Vinceburns</h2>
              <p>
                $VINCE has a unique burning mechanism that rewards the community for Vincencius Jooniur's success on the field. For every assist or goal he scores, 0.1% of the total supply will be burned. If Brazil reaches the Semi Final of Copa America, 0.5% of the total supply will be burned. If Brazil reaches the Final, 1% of the total supply will be burned.
              </p>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="inner">
            <h2>How to Buy $VINCE?</h2>

            <div className="steps">
              <Card>
                <h3>CREATE A WALLET</h3>
                <p>Download Phantom or your wallet of choice from the app store or Google Play for free. Desktop users, download the Google Chrome extension by going to Phantom.</p>
                <Button href="https://phantom.app/" target="_blank">
                  Download Phantom
                </Button>
              </Card>

              <Card>
                <h3>Get Some SOL</h3>
                <p>Have SOL in your wallet to switch to $VINCE. If you don’t have any SOL, you can buy directly on Phantom, transfer from another wallet, or buy on another exchange and send it to your wallet.</p>
              </Card>

              <Card>
                <h3>Go to Raydium</h3>
                <p>Connect to Raydium. Go to Raydium in google chrome or on the browser inside your Phantom app. Connect your wallet. Paste the $VINCE token address into Raydium, select $VINCE, and confirm. When Phantom prompts you for a wallet signature, sign.</p>
                <Button href="https://raydium.io/swap/?inputMint=sol&outputMint=8BQdCwV2dtEpPRcwk285MWNZZN2K6jfQ3hFobg7NDASt" target="_blank">
                  Go to Raydium
                </Button>
              </Card>

              <Card>
                <h3>Buy $VINCE</h3>
                <p>Switch SOL for $VINCE. We have Zero taxes so you don’t need to worry about buying with a specific slippage, although you may</p>
              </Card>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="inner">
            <h2>Socials</h2>
            <h3>Join the $VINCE Community</h3>

            <Button href="https://x.com/VinceJooniur" target="_blank" IconComponent={LogoX}>
              Twitter
            </Button>
            <Button href="https://t.me/+7u-a-FMCyto2YTc0" target="_blank" IconComponent={LogoTelegram}>
              Telegram
            </Button>
            <Button href="https://dexscreener.com/solana/a9l5qyum3apugzyflspxhjs6cbps9fubxdh46sig1wvc" target="_blank" IconComponent={LogoDexscreener}>
              DexScreener
            </Button>
          </div>
        </div>
      </section>

      <section className="footer">
        <div className="container">
          <div className="inner">
            <p className="disclaimer">
              <small>$VINCE is a memecoin and has no intrinsic value or expectation of financial returns. $VINCE has no affiliation with Vinicius Junior. Please do your own research before investing in $VINCE.</small>
            </p>
          </div>
        </div>
      </section>

      <div className="vini-gif">
        <img src={vinceGif} alt="Vini" />
      </div>

    </div>
  );
}

export default App;
