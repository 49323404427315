import React, { useRef } from 'react';
import './Button.css';

export default function Button({ icon, IconComponent, children, href, onClick, ...props }) {
  const buttonRef = useRef(null);

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }

    const button = buttonRef.current;
    let drop = button.querySelector('.button-drop');
    if (!drop) {
      drop = document.createElement('span');
      drop.className = 'button-drop';
      button.prepend(drop);
    }
    drop.classList.remove('clicked');
    const d = Math.max(button.offsetWidth, button.offsetHeight);
    drop.style.height = `${d}px`;
    drop.style.width = `${d}px`;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left - drop.offsetWidth / 2;
    const y = e.clientY - rect.top - drop.offsetHeight / 2;
    drop.style.top = `${y}px`;
    drop.style.left = `${x}px`;
    drop.classList.add('clicked');
  };

  const content = (
    <>
      <div className="button-child">
        {icon && typeof icon === 'string' && <img src={icon} alt="icon" className="button-icon" />}
        {IconComponent && <IconComponent className="button-icon" />}
        {children}
      </div>
    </>
  );

  return href ? (
    <a href={href} className="button" onClick={handleClick} ref={buttonRef} {...props}>
      {content}
    </a>
  ) : (
    <button className="button" onClick={handleClick} ref={buttonRef} {...props}>
      {content}
    </button>
  );
}